import { Reducer } from 'redux';
import {
  CastCheckpointAssignment, CastTrack, CastCheckpoint, CastProfile,
} from '@gomycode/types-utils/Types/Casters';
import constants from './constants';
import { CheckpointsState } from './types';
import {
  RATE_STUDENT_STATUS_MAP,
  generateCheckpointHistory,
  getCheckpointRateStudentStatus,
} from './helpers';

const initialState: CheckpointsState = {
  errors: undefined,
  loading: false,
  checkpoints: [],
  allCheckpoints: [],
  checkpointRateStudentStatus: 0,
  checkpoint: CastCheckpoint(),
  showValidateCheckpointModal: false,
  showDeadlineModal: false,
  shouldRefetchCheckpoint: false,
  approveWoolfCheckpoint: false,
  topStudentsData: {
    scoreProfileResponses: [],
    numberOfStudentsWhoPassed: 0,
  },
  checkpointAssignment: {
    ...CastCheckpointAssignment(),
    updatedAt: '',
    nameFr: '',
    linkSubmissionGuidelineFr: '',
    createdAt: '',
  },
  student: CastProfile(),
  track: CastTrack(),
};

// eslint-disable-next-line default-param-last
const reducer: Reducer<CheckpointsState> = (state = initialState, action) => {
  switch (action.type) {
    case constants.getUnclosedCheckpoints.request:
      return { ...state, loading: true };
    case constants.getUnclosedCheckpoints.success: {
      return {
        ...state,
        loading: false,
        checkpoints: action.data.model,
      };
    }
    case constants.getUnclosedCheckpoints.failure: {
      return { ...state, loading: false, errors: [] };
    }

    case constants.getClosedCheckpoints.request:
      return { ...state, loading: true };
    case constants.getClosedCheckpoints.success: {
      return {
        ...state,
        loading: false,
        checkpoints: action.data.model,
      };
    }
    case constants.getClosedCheckpoints.failure: {
      return { ...state, loading: false, errors: [] };
    }

    case constants.getUnlockedCheckpoints.request:
      return { ...state, loading: true };
    case constants.getUnlockedCheckpoints.success: {
      return {
        ...state,
        loading: false,
        checkpoints: action.data.model,
      };
    }
    case constants.getUnlockedCheckpoints.failure: {
      return { ...state, loading: false, errors: [] };
    }

    case constants.getAllCheckpoints.request:
      return { ...state, loading: true };
    case constants.getAllCheckpoints.success:
      return { ...state, allCheckpoints: action.data.model, loading: false };
    case constants.getAllCheckpoints.failure:
      return { ...state, loading: false, errors: [] };

    case constants.getCheckpointById.request:
      return { ...state, loading: true, shouldRefetchCheckpoint: false };
    case constants.getCheckpointById.success:
      // eslint-disable-next-line no-case-declarations
      const { reviewsList } = action.data.model;
      // eslint-disable-next-line no-case-declarations
      const checkpointHistory = generateCheckpointHistory(
        reviewsList,
        action.data.model,
      );
      return {
        ...state,
        loading: false,
        ...action.data.model,
        checkpointHistory,
        checkpointRateStudentStatus:
          getCheckpointRateStudentStatus(checkpointHistory),
      };
    case constants.getCheckpointById.failure:
      return {
        ...state,
        loading: false,
        errors: [],
      };

    case constants.fetchCheckpointTopStudents.request:
      return {
        ...state,
        loading: true,
      };
    case constants.fetchCheckpointTopStudents.success:
      return {
        ...state,
        loading: false,
        topStudentsData: action.data.model,
      };
    case constants.fetchCheckpointTopStudents.failure:
      return {
        ...state,
        loading: false,
        errors: [],
      };

    case constants.updateRateStudentTabStatus.success:
      return {
        ...state,
        checkpointRateStudentStatus: action.data,
      };

    case constants.addReview.request:
      return {
        ...state,
        loading: true,
      };
    case constants.addReview.success:
      return {
        ...state,
        loading: false,
        checkpointRateStudentStatus: RATE_STUDENT_STATUS_MAP.rejected,
      };

    case constants.showValidateCheckpointModal.success:
      return {
        ...state,
        showValidateCheckpointModal: action.data,
      };

    case constants.showDeadlineModal.success:
      return {
        ...state,
        showDeadlineModal: action.data,
      };

    case constants.approveCheckpoint.request:
      return {
        ...state,
        loading: true,
      };
    case constants.approveCheckpoint.success:
      return {
        ...state,
        loading: false,
        checkpointRateStudentStatus: RATE_STUDENT_STATUS_MAP.validated,
        shouldRefetchCheckpoint: true,
      };

    case constants.approveWoolfCheckpoint.request:
      return {
        ...state,
        approveWoolfCheckpoint: true,
      };
    case constants.approveWoolfCheckpoint.success:
      return {
        ...state,
        approveWoolfCheckpoint: false,
        checkpointRateStudentStatus: RATE_STUDENT_STATUS_MAP.validated,
        shouldRefetchCheckpoint: true,
      };

    default: {
      return state;
    }
  }
};

// eslint-disable-next-line import/prefer-default-export
export { reducer as checkpointsReducer };
